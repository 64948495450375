import React from 'react';

export const Feature = ({title, description, icon}) => {
  const sentenceWithFirstWordSeparated = (word) => {
    let firstBlank = word.indexOf(' ');
    if (firstBlank === -1) {
      return word;
    }
    return [word.slice(0, firstBlank), word.slice(firstBlank, -1)];
  }

  const separatedTitle = sentenceWithFirstWordSeparated(title);

  return (
    <div className="feature">
      <div className="icon">
        <img src={icon} alt={title} />
      </div>
      <div className="content">
        <h3><span>{separatedTitle[0]}</span>{separatedTitle[1]}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}

