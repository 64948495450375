import React from "react";
import aboutImage from '../assets/papatronica_2.png';
import aboutIcons from '../assets/about-icons.png';
import '../styles/About.scss';

export const About = () => {
  return (
    <div className="about">
      <div className="papatronica-image-container">
        <img src={aboutImage} alt="Papatrónica"/>
      </div>
      <div className="info-container">
        <div className="icons-container">
            <img src={aboutIcons} alt="Proceso"/>
          </div>
          <div className="text-container">
            <h2>
              <span>IDENTIFICÁ</span> EN TIEMPO REAL LOS <span>PUNTOS CRÍTICOS</span> DEL PROCESO DONDE LAS PAPAS
              SUFREN MAYORES GOLPES, <span>DESDE</span> LA COSECHA <span>HASTA</span> LA LÍNEA DE PRODUCCIÓN.
            </h2>
          </div>
        </div>
    </div>
  );
}