import React from "react";
import appScreenshot from '../assets/thresholds-screenshot.png';
import '../styles/Technology.scss';

export const Technology = () => {
  return (
    <div className="technology">
      <div className="text-container">
        <h2>TECNOLOGÍA</h2>
        <p>El dispositivo replica la morfología y el comportamiento de una papa real para obtener mediciones más
          representativas durante el proceso.</p>
        <p>Papatrónica cuenta en su interior con un sensor acelerómetro que permite determinar la fuerza con la que se
          golpean las papas. Al definir un umbral de daño según la variedad cosechada, nos alerta cuando los golpes
          ponen en peligro la calidad del producto.</p>
      </div>
      <div className="image-container">
        <img src={appScreenshot} alt="App Screenshot"/>
      </div>
    </div>
  )
}