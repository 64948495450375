import React from "react";
import logo from '../assets/papatronica-logo.png';
import backgroundImage from '../assets/header-background.png';
import '../styles/Header.scss';

export const Header = () => {
  return (
    <div className="header" style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className="content">
        <div className="logo-container">
          <img src={logo} alt="Papatrónica Logo" className="logo"/>
        </div>
        <div className="text-container">
          <h1>MEDICIÓN DE DAÑOS EN TUBÉRCULOS.</h1>
          <p>by @eryxcoop</p>
        </div>
      </div>
    </div>
  )
}