import React from 'react';
import iconConnect from '../assets/bluetooth-signal-usage.png';
import iconMeasure from '../assets/measure-icon-usage.png';
import iconSave from '../assets/save-icon-usage.png';
import manualDownload from '../assets/download-icon-usage.png';
import UsageManualPDF from "../assets/files/manual-de-usuario.pdf";
import '../styles/Manual.scss';


const downloadUserManualButton = () => {
    return (
        <a className="manual-download" href={UsageManualPDF} download>
            <img src={manualDownload} alt="Descargar manual"/>
            <p>
                Descargá el manual de usuario completo acá.
            </p>
        </a>
    );
}
export const Manual = () => {
    return (
        <div className="usage-manual">
            <div className="manual-header">
                <div className="header-container">
                    <h2>MANUAL DE USO</h2>
                </div>
            </div>
            <div className="steps">
                <div className="step">
                    <img src={iconConnect} alt="Conectar"/>
                    <h3>CONECTÁ</h3>
                    <p>Encendé Papatrónica golpeándola 3 veces y asegurate de habilitar el bluetooth de tu dispositivo
                        Android para poder comenzar.</p>
                </div>
                <div className="step">
                    <img src={iconMeasure} alt="Medir"/>
                    <h3>MEDÍ</h3>
                    <p>Colocá Papatrónica donde desees medir. Observá cómo el dispositivo
                        es cosechado e identificá
                        los momentos en que recibe golpes que podrían dañar
                        los tubérculos.</p>
                </div>
                <div className="step">
                    <img src={iconSave} alt="Guardar"/>
                    <h3>GUARDÁ</h3>
                    <p>Revisá el historial de mediciones realizadas en la aplicación o exportalas para realizar un
                        análisis más exhaustivo de los datos.</p>
                </div>
            </div>
            {downloadUserManualButton()}
        </div>
    );
}