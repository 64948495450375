import React from "react";
import {Specification} from "./Specification";
import {Manual} from "./Manual";
import '../styles/Specifications.scss';

export const Specifications = () => {
    const specsData = [
        {
            title: "Dimensiones",
            content: "Peso: 250 grs. </br> Medidas: 110x60x55 cm.",
        },
        {
            title: "Bluetooth",
            content: "Bluetooth 5.0 BLE con un alcance de hasta 150 m.",
        },
        {
            title: "Batería",
            content: "Tipo: batería de litio 3.7v 500mAh \n Duración: hasta 6 hs de uso continuo y hasta 15 días apagada. \n Carga: 2 horas (aprox.)\n",
        },
        {
            title: "Detección de golpes",
            content: "Sensor acelerómetro de 3 ejes que registra impactos de hasta 200g.",
        },
        {
            title: "Manejo de datos",
            content: "Toma más de 3000 mediciones por segundo para que no se pierda ningún golpe importante."
        }
    ];

    return (
        <section className="specifications">
            <h2>ESPECIFICACIONES TÉCNICAS</h2>
            <div className="specs-container">
                <div>
                    <Specification key="Dimensiones" title="Dimensiones">
                        Peso: <br></br>250 grs. <br></br><br></br> Medidas:<br></br> 110x60x55 cm.
                    </Specification>
                    <Specification key="Bluetooth" title="Bluetooth">
                        Bluetooth 5.0 BLE con un alcance de hasta 150 m.
                    </Specification>
                </div>
                <div>
                    <Specification key="Batería" title="Batería">
                        Tipo: <br></br>batería de litio 3.7v 500mAh.<br></br><br></br> Duración: hasta 6 hs de uso
                        continuo y
                        hasta 15 días
                        apagada.<br></br> <br></br> Carga: 2 horas (aprox.)
                    </Specification>
                </div>
                <div>
                    <Specification key="Detección de golpes" title="Detección de golpes">
                        Sensor acelerómetro de 3 ejes que registra impactos de hasta 200g.
                    </Specification>
                    <Specification key="Manejo de datos" title="Manejo de datos">
                        Toma más de 3000 mediciones por segundo para que no se pierda ningún golpe importante.
                    </Specification>
                </div>
            </div>
            <Manual/>
        </section>
    );
}