import React from "react";
import '../styles/Papatronica.scss';
import armWithPotato from '../assets/brazo.png';

export const Papatronica = () => {
    return (
        <div className="papatronica-container">
            <div className="figure-container">
                <img src={armWithPotato} alt="Brazo sosteniendo Papatrónica"/>
                <div className="figure"></div>
            </div>
            <div className="papatronica-info">
                <p>
                    <b>Papatrónica</b> es un dispositivo que, a través de una aplicación,
                    permite determinar en tiempo real en qué parte del proceso
                    y con cuánta intensidad se golpean las papas cosechadas.
                </p>
                <p>
                    Con esta información, se pueden corregir o ajustar las etapas
                    donde se producen daños en exceso, reduciendo el desperdicio
                    y mejorando la calidad del producto.
                </p>
            </div>
        </div>
    );
}