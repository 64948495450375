import React from "react";
import {Feature} from "./Feature";
import PotatoIcon from "../assets/potato-icon.svg";
import ThresholdsIcon from "../assets/threholds-icon.svg";
import ChartIcon from "../assets/chart-icon.svg";
import SaveIcon from "../assets/save-icon.svg"
import SignalIcon from "../assets/signal-icon.svg";
import SafeIcon from "../assets/safe-icon.svg";
import '../styles/Features.scss';

export const Features = () => {
  const featuresData = [
    {
      title: "USALA COMO SI FUESE UNA PAPA MÁS.",
      description: "Su forma y densidad imitan la de una papa real, para que la recepción de los golpes sea idéntica a la de los tubérculos cosechados.",
      icon: PotatoIcon,
    },
    {
      title: "CONFIGURALA SEGÚN TU COSECHA.",
      description: "El dispositivo permite modificar los umbrales de intensidad de los golpes para una mayor especificidad.",
      icon: ThresholdsIcon,
    },
    {
      title: "SEGUÍ LAS MEDICIONES DESDE TU CELULAR.",
      description: "Papatrónica funciona con una aplicación compatible con cualquier dispositivo Android. No se necesita equipamiento especial.",
      icon: ChartIcon,
    },
    {
      title: "GUARDÁ TUS DATOS.",
      description: "Las mediciones no sólo se realizan en tiempo real, sino que pueden revisarse en la aplicación o descargarlas para su posterior análisis.",
      icon: SaveIcon,
    },
    {
      title: "MEDÍ CON PRECISIÓN.",
      description: "Papatrónica cuenta con sensores inteligentes que obtienen datos  en tiempo real y durante todo el proceso.",
      icon: SignalIcon,
    },
    {
      title: "IMPLEMENTALA EN TODAS TUS COSECHAS.",
      description: "Papatrónica cuenta con una carcasa diseñada especialmente para que reciba golpes a lo largo del tiempo sin dañarse.",
      icon: SafeIcon,
    }
  ];

  return (
    <section className="features">
      <div>
        <h2>CARACTERÍSTICAS</h2>
      </div>
      <div className="feature-grid">
        {featuresData.map((feature, index) => (
          <Feature key={index} title={feature.title} description={feature.description} icon={feature.icon}/>
        ))}
      </div>
    </section>
  );
}