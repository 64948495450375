import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {PrivacyPolicy} from "./screens/PrivacyPolicy";
import {Home} from "./screens/Home";
import {UsageManual} from "./components/UsageManual";

const router = createBrowserRouter([
  {
    path: "/politica-de-privacidad",
    element: <PrivacyPolicy/>,
  },
  {
    path: "/manual-de-usuario",
    element: <UsageManual/>,
  },
  {
    path: "/",
    element: <Home/>,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
