import React from "react";
import '../styles/Project.scss';

export const Project = () => {
    return (
        <div className="project">
            <h2>PROYECTO</h2>
            <p>
              El valor del proyecto radica en la conjunción de distintos sectores a fin de encontrar la mejor
              solución posible. En <b>Papatrónica</b> no solo se combinó el trabajo de la Academía con la industria
              sino que contamos en todo momento con el feedback del usuario principal: los <b>productores</b>.
              <br/>
              El equipo del proyecto fue conformado por ingenieros agrónomos, en informática y en electrónica,
              desarrolladores, diseñadores gráficos e industriales y comunicadores.
            </p>
        </div>
    );
}