import React from "react";
// Logos
import logoEryx from '../assets/logos/logo-eryx.png';
import logoUnicen from '../assets/logos/logo-unicen.png';
import logoAgencia from '../assets/logos/logo-agencia.png';
import logoInta from '../assets/logos/logo-inta.png';
import logoMdq from '../assets/logos/logo-mdq.png';
import logoMincyt from '../assets/logos/logo-mincyt.png';

import '../styles/Footer.scss';

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="foot-header">
                <p>Además, cuenta con el apoyo de las siguientes instituciones:</p>
            </div>
            <div className="support">
                <div className="logos">
                    <img src={logoEryx} alt="Eryx"/>
                    <img src={logoUnicen} alt="Unicen"/>
                    <img src={logoAgencia} alt="Agencia I+D+I"/>
                    <img src={logoInta} alt="Inta"/>
                    <img src={logoMdq} alt="Universidad Nacional de Mar del Plata"/>
                    <img src={logoMincyt} alt="Ministerio de ciencia, tecnología e innovación"/>
                </div>
            </div>
            <div className="cta">
                Si te interesa el proyecto, podés conocer más <a href="https://www.youtube.com/watch?v=bckHiPg0PmY&list=PLznGnkMcSfEAV6_XYoxQf_xLDnT6pHACP" target="_blank">acá.</a>
            </div>
        </footer>
    )
}