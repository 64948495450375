import React from "react";
import '../index.css';
export const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <h1>Política de Privacidad</h1>
      <h3>Nosotros</h3>
      <p>
        Para nosotros es sumamente importante proteger la privacidad de nuestros Usuarios.
        Esta  declaración  de  privacidad  brinda  información  sobre  cómo  manejamos  sus  datos
        personales, su derecho a la privacidad y cómo la ley le brinda protección.
        Por  lo  tanto,  antes  de  llevar  a  cabo  cualquier  interacción  en Papatronica,  es
        esencial  que  conozca  y  acepte  todos  los  detalles  de  esta  Política  de  Privacidad.  En  caso
        de  no  estar  de  acuerdo  con  la  misma,  por  favor,  no utilice la plataforma.
        Toda  la  información  recopilada se mantendrá protegida según las políticas de confidencialidad que
        se exponen a continuación.
      </p>
      <h3>Recopilación y uso de información personal</h3>
      <p>
        Papatronica no recopila información ni almacena información personal.
      </p>
      <h3>Recopilación y uso de información técnica</h3>
      <ul>
        <li>
          La principal funcionalidad de Papatronica es medir el impacto que sufren las papas durante la cosecha y los
          diferentes procesos de poscosecha. Para esto es necesario almacenar la intensidad de los golpes, el momento y la
          localización geográfica de los mismos para un posterior análisis. Posteriormente y de manera opcional, el
          usuario puede exportar las mediciones en un archivo que se guardará en la memoria local del teléfono.
          Sin embargo, para esto es estrictamente necesario que el usuario autorice el uso del bluetooth, la ubicación y
          el acceso a documentos.
        </li>
        <li>
          Con el fin de garantizar una experiencia completa se trackea información relacionada con el uso de la
          aplicación y posibles errores. Estas estadísticas son anónimas y solo son utilizadas para mejorar las
          distintas funcionalidades.
        </li>
        <li>No compartimos la información mencionada anteriormente con terceros.</li>
      </ul>
      <h3>Seguridad</h3>
      <p> Nuestros servidores están ubicados en un entorno seguro. Como ningún sistema está completamente excento
        de fallas, no podemos garantizar de manera absoluta la seguridad de los datos. Sin embargo, empleamos todas las
        medidas de seguridad estandar en la industria para evitar que los datos lleguen a cualquier persona que no sea
        parte del proyecto.
      </p>
      <h3>Cookies</h3>
      <p>No se utilizan cookies de ningún tipo</p>
      <h3>Publicidad</h3>
      <p> Papatronica no muestra ningún tipo de publicidad.</p>
      <h3>Información de contacto</h3>
      <a href="mailto:labs@eryx.co" type="email">labs@eryx.co</a>
    </div>
  )
}