import React from "react";
import {Header} from "../components/Header";
import {About} from "../components/About";
import {Technology} from "../components/Technology";
import {Features} from "../components/Features";
import {Specifications} from "../components/Specifications";
import {Project} from "../components/Project";
import {Footer} from "../components/Footer";
import {Papatronica} from "../components/Papatronica";

export const Home = () => {
    return (
        <div>
            <Header/>
            <About/>
            <Papatronica/>
            <Technology/>
            <Features/>
            <Specifications/>
            <Project/>
            <Footer/>
        </div>
    )
}